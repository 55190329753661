exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-buylis-components-footer-tsx": () => import("./../../../src/pages/buylis/components/footer.tsx" /* webpackChunkName: "component---src-pages-buylis-components-footer-tsx" */),
  "component---src-pages-buylis-components-header-tsx": () => import("./../../../src/pages/buylis/components/header.tsx" /* webpackChunkName: "component---src-pages-buylis-components-header-tsx" */),
  "component---src-pages-buylis-form-index-tsx": () => import("./../../../src/pages/buylis/form/index.tsx" /* webpackChunkName: "component---src-pages-buylis-form-index-tsx" */),
  "component---src-pages-buylis-form-ja-index-tsx": () => import("./../../../src/pages/buylis/form/ja/index.tsx" /* webpackChunkName: "component---src-pages-buylis-form-ja-index-tsx" */),
  "component---src-pages-buylis-index-tsx": () => import("./../../../src/pages/buylis/index.tsx" /* webpackChunkName: "component---src-pages-buylis-index-tsx" */),
  "component---src-pages-buylis-ja-index-tsx": () => import("./../../../src/pages/buylis/ja/index.tsx" /* webpackChunkName: "component---src-pages-buylis-ja-index-tsx" */),
  "component---src-pages-buylis-privacy-policy-en-index-tsx": () => import("./../../../src/pages/buylis/privacy-policy/en/index.tsx" /* webpackChunkName: "component---src-pages-buylis-privacy-policy-en-index-tsx" */),
  "component---src-pages-buylis-privacy-policy-ja-index-tsx": () => import("./../../../src/pages/buylis/privacy-policy/ja/index.tsx" /* webpackChunkName: "component---src-pages-buylis-privacy-policy-ja-index-tsx" */),
  "component---src-pages-index-ja-tsx": () => import("./../../../src/pages/index.ja.tsx" /* webpackChunkName: "component---src-pages-index-ja-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-simple-yosan-tsx": () => import("./../../../src/pages/privacyPolicy/simpleYosan.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-simple-yosan-tsx" */)
}

